<template>
  <div class="images-container">
    <div class="left-container">
      <template v-for="image in letImages">
        <ImageEditor
          class="cursor-pointer"
          :key="image.id"
          :image_id="image.id"
          :allows_crud="allows_crud"
          @zoomIn="slotZoomIn"
          @deleted="slotImageDeleted"
        ></ImageEditor>
      </template>
    </div>
    <div class="center-container">
      <template v-for="image in centerImages">
        <ImageEditor
          class="cursor-pointer"
          :key="image.id"
          :image_id="image.id"
          :allows_crud="allows_crud"
          @zoomIn="slotZoomIn"
          @deleted="slotImageDeleted"
        ></ImageEditor>
      </template>
    </div>
    <div class="right-container">
      <template v-for="image in rightImages">
        <ImageEditor
          class="cursor-pointer"
          :key="image.id"
          :image_id="image.id"
          :allows_crud="allows_crud"
          @zoomIn="slotZoomIn"
          @deleted="slotImageDeleted"
        ></ImageEditor>
      </template>
    </div>
    <div v-if="current_image"></div>
    <b-modal
      v-if="current_image"
      :title="current_image.name"
      :id="`img-modal-${current_image.id}`"
      size="xl"
      hide-footer
    >
      <!-- TODO: Agregar atributo "name" en imagen para poder trabajar títulos. -->
      <b-img :src="current_image.image" fluid alt="Imagen"></b-img>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ImagesContainer",
  components: {
    ImageEditor: () => import("./ImageEditor"),
  },
  props: {
    Images: {
      type: [Array, Object],
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      current_image: null,
    };
  },
  computed: {
    images() {
      if (Array.isArray(this.Images)) {
        return this.Images;
      }
      return [this.Images];
    },
    letImages() {
      return this.images.filter((x) => x.horizontal_align == 1);
    },
    centerImages() {
      return this.images.filter((x) => x.horizontal_align == 2);
    },
    rightImages() {
      return this.images.filter((x) => x.horizontal_align == 3);
    },
  },
  methods: {
    slotZoomIn(image) {
      this.current_image = image;
      setTimeout(() => {
        this.$bvModal.show(`img-modal-${image.id}`);
      }, 100);
    },
    slotImageDeleted(image_id) {
      this.$emit("deleted", image_id);
    },
  },
};
</script>

<style scoped>
.images-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.left-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 33%;
  justify-content: left;
}
.center-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 33%;
  justify-content: center;
  align-items: center;
}
.right-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 33%;
  justify-content: flex-end;
  align-self: center;
  text-align: end;
}
</style>